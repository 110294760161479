import React, {Fragment, useState} from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image";
import {Helmet} from "react-helmet";
// components
import HeaderQuery from "../components/header/header";
import BasicBlock from "../components/basicBlock/basicBlock";
import CardContainer from "../components/cardContainer/cardContainer";
import CardOneProjet from "../components/card/cardOneProjet";
import Footer from "../components/footer/footer";
// styles
import '../styles/projetsPage.css'

const Projets = ({ data, path }) => {
  //variables
  const [selectorType, setSelectorType] = useState('')
  const {headerPicture, allTypeProjets, allProjects} = data;
  // render
  return (
    <main className="projets-main">
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="fr" />
        <title>Projets</title>
        <link rel="canonical" href="https://webdevvision.fr/projets/" />
        <meta
          name="description"
          content="Nous sommes une agence de développement Web et Mobile sur Gaillac, passionnée et engagée dans la stratégie digitale, la conception UI / UX et le développement d’applications web et mobile."
        />
      </Helmet>
      <HeaderQuery />
      <BasicBlock
        containerLeft={() => (
          <>
            <h1 className="primary projets-header">
              Nos Projets
            </h1>
            <p className="secondary projets-subTitle">
              Nous sommes prêts à relever des défis toujours plus audacieux, que vous soyez indépendant ou issu d'un grand groupe.
              Notre flexibilité s'illustre dans la variété de projets qui nous ont été confiés.
              Nous vous invitons à découvrir nos réalisations ci-dessous.
            </p>
          </>
        )}
        containerRight={() => (
          <>
            <Img fluid={headerPicture.childImageSharp.fluid}  />
          </>
        )}
        containerRightFirstOnMobile
        containerRightContainPicture
        containerLeftContainPicture={false}
        background="tertiary"
      />
      <div className="projets-container-title">
        <span className="bg-secondary projets-span" />
        <div
          tabIndex="0"
          onKeyPress={() => {}}
          onClick={() => setSelectorType('')}
          role="button"
          className="projets-title-button"
        >
          <h3 className={selectorType === '' ? 'active' : 'inactive'}>Tous</h3>
        </div>
        {allTypeProjets.edges.map(item => (
          <Fragment key={item.node.id}>
            <span className="bg-secondary projets-span" />
            <div
              tabIndex="0"
              onKeyPress={() => {}}
              onClick={() => setSelectorType(item.node.name)}
              role="button"
              className="projets-title-button"
            >
              <h3 className={selectorType === item.node.name ? 'active' : 'inactive'}>{item.node.name}</h3>
            </div>
          </Fragment>
        ))}
        <span className="bg-secondary projets-span" />
      </div>
      <CardContainer isFullResponsive={true}>
        {allProjects.edges.map(item => {
          const {type_projet, url_name, technos, id, logo_img, subTitle} = item.node;
          if (type_projet.name.includes(selectorType)) {
            return (
              <CardOneProjet
                key={id}
                url={url_name}
                techno={technos}
                borderColor={type_projet.color}
                image={
                  <div className="projets-oneProjet-container-picture">
                    <Img imgStyle={{objectFit: 'contain', maxHeight: 200}} fluid={logo_img.childImageSharp.fluid}  />
                  </div>
                }
                text={
                  <div className="projets-oneProjet-text-container">
                    <p className="secondary">{subTitle}</p>
                  </div>
                }
                title={
                  <div className="projets-oneProjet-title-container">
                    <h4 className={`projets-oneProjet-type ${type_projet.color}`}>
                      {`Projet ${type_projet.name}`}
                    </h4>
                    <h3 className="secondary">{item.node.title}</h3>
                  </div>
                }
              />
            )
          }
          return null
        })}
      </CardContainer>
      <Footer path={path} />
    </main>
  )
}

export const query = graphql`
  query {
    headerPicture: file(relativePath: { eq: "projets-header.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allProjects: allStrapiProjets {
      edges {
        node {
          id,
          url_name,
          title,
          subTitle,
          logo_img {
            id,
            childImageSharp {
              fluid(maxHeight: 200, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          },
          technos {
           id,
           name,
          },
          type_projet {
            id,
            name,
            color,
          }
        }
      }
    }
    allTypeProjets: allStrapiTypeProjets {
    edges {
      node {
        id,
        name,
      }
    }
  }
  }
`

export default Projets
