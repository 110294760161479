import React from 'react'
import PropTypes from "prop-types";
// style
import './cardOneTechno.css'

const CardOneTechno = ({data, noPadding}) => (
  <ul className={noPadding ? "cop-container-techno-np" : "cop-container-techno"}>
    {data.map(item => (
      <li key={item.id} className="card-one-techno-container">
        <p className="card-one-techno-round">{item.name}</p>
      </li>
    ))}
  </ul>
)

CardOneTechno.propTypes = {
  data: PropTypes.array.isRequired,
  noPadding: PropTypes.bool.isRequired,
}

export default CardOneTechno
