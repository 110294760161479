import React from 'react'
import PropTypes from "prop-types";
import { Link } from "gatsby"
// components
import CardOneTechno from "./cardOneTechno";
// style
import './cardOneProjet.css'

const CardOneProjet = ({image, title, text, borderColor, url, techno}) => (
  <Link to={"/projets/" + url + '/'} className="cop-container-all">
    <div className={`cop-sub-container bd-${borderColor}`}>
      {image}
      {title}
      {text}
      <CardOneTechno data={techno} noPadding={false} />
    </div>
  </Link>
)

CardOneProjet.propTypes = {
  image: PropTypes.element.isRequired,
  title: PropTypes.element.isRequired,
  text: PropTypes.element.isRequired,
  borderColor: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  techno: PropTypes.array.isRequired,
}

export default CardOneProjet
