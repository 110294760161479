import React from 'react'
import PropTypes from "prop-types";
// style
import './cardContainer.css'

const CardContainer = ({children, isFullResponsive}) => (
  <div className={isFullResponsive ? 'cc-container fullResponsive' : 'cc-container'}>
      {children}
  </div>
)

CardContainer.propTypes = {
    children: PropTypes.node.isRequired,
    isFullResponsive: PropTypes.bool.isRequired,
}

export default CardContainer
