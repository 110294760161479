import React from 'react'
import PropTypes from "prop-types";
// style
import './basicBlock.css'

const BasicBlock = (props) => {
  const {
    containerRight,
    containerLeft,
    containerRightFirstOnMobile,
    containerRightContainPicture,
    containerLeftContainPicture,
    background,
  } = props;

  return (
    <div className={`bb-container bg-${background}`}>
      <div className={!containerRightFirstOnMobile ? "bb-container-left bb-first" : "bb-container-left"}>
        <div className={containerLeftContainPicture ? "bb-container-picture" : "bb-container-all-text"}>
          {containerLeft()}
        </div>
      </div>
      <div className={containerRightFirstOnMobile ? "bb-container-right bb-first" : "bb-container-right"}>
        <div className={containerRightContainPicture ? "bb-container-picture" : "bb-container-all-text"}>
          {containerRight()}
        </div>
      </div>
    </div>
  )
}

BasicBlock.propTypes = {
  containerRight: PropTypes.func.isRequired,
  containerLeft: PropTypes.func.isRequired,
  containerRightFirstOnMobile: PropTypes.bool.isRequired,
  containerRightContainPicture: PropTypes.bool.isRequired,
  containerLeftContainPicture: PropTypes.bool.isRequired,
  background: PropTypes.string.isRequired,
}

export default BasicBlock
