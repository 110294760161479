import React from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PropTypes from "prop-types";
// style
import './footer.css'

const Footer = ({path}) => (
  <div className="f-container">
    <ul className="f-container-link">
      <li><a rel="noreferrer" target="_blank" href="https://twitter.com/GuillaumeSauss1?s=09">Twitter</a></li>
      <li className="f-separator">|</li>
      <li><a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/guillaume-saussol-7732a6145">LinkedIn</a></li>
    </ul>
    {path !== '/contact/' && (
      <div className="f-container-button">
        <AniLink
          paintDrip
          hex="#FFC13B"
          className="f-button"
          to="/contact/"
        >
          Contactez-nous
        </AniLink>
      </div>
    )}
  </div>
)

Footer.propTypes = {
  path: PropTypes.string.isRequired,
}

export default Footer
